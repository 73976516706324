import React, { useCallback, useRef, useState } from 'react';
import Select from 'react-select';

import {
  Grid,
  Typography,
  Button,
  Dialog,
  TextField,
  FormControl,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Checkbox
} from '@material-ui/core';
import { Close, Warning } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { isEmpty } from 'lodash';

import moment from 'moment';
import ProductSearchForPO from './components/ProductSearchForPO';
import POLineItem from './components/POLineItem';
import PurchaseOrderMeta from './components/PurchaseOrderMeta';
import PurchasingNotes from './components/PurchasingNotes';
import PurchaseOrderReceiving from './components/PurchaseOrderReceiving';
import PurchaseOrderUnReceiving from './components/PurchaseOrderUnReceiving';
import Can, { check } from '../../roles/Can';
import TableView from '../../common/LayoutComponents/Table/TableView';
import AccountsPayableInvoices from '../AccountsPayableInvoice/AccountsPayableInvoices';
import AttachedInvoices from '../AccountsPayableInvoice/AttachedInvoices';
import { looksLikeUpc } from '../../helpers/upcHelpers';
import ScanEventSubscriber from '../../common/ScanEventListening/ScanEventSubscriber';
import { matchesUpc } from '../../helpers/scanValidator';
import { PurchaseOrderDetailsProps } from './PurchaseOrderDetailsTypes';
import { ScanEventHandler } from '../../common/ScanEventListening/ScanEventListeningTypes';
import { usePurchaseOrderDetailsStyles } from './PurchaseOrderDetailsStyles';
import { GlueCopyToClipboardIconButton } from '../Presentational/GlueCopyToClipboardIconButton';

const NO_SERIALS_FOUND_MESSAGE: string =
  "No serials found. Most likely this item isn't serial tracked.";

export default function PurchaseOrderDetails(props: PurchaseOrderDetailsProps) {
  const {
    vendors,
    poData,
    poIsUpdating,
    showPurchaseOrder,
    role,
    poEditingDisabled,
    setPOEditingDisabled,
    addProductToPO,
    composingPOEmail,
    setComposingPOEmail,
    poVendorEmail,
    setPOVenderEmail,
    poEmailContent,
    setPOEmailContent,
    poEmailCCs,
    setPOEmailCCs,
    poEmailRecipientName,
    setPOEmailRecipientName,
    handleDeletePOItem,
    handleUpdatePOItem,
    poItemToReceive,
    initializeReceiving,
    initializeUnReceiving,
    endReceiving,
    endUnReceiving,
    isReceiving,
    isUnReceiving,
    printPurchaseOrderAsPDF,
    savePurchaseOrderAsPDF,
    sendPurchaseOrderAsPDF,
    handleFinishPurchaseOrder,
    poTrackingNumber,
    setPOTrackingNumber,
    poCarrier,
    setPOCarrier,
    handleUpdatePOStatusToOrderConfirmed,
    vendorOrderId,
    setVendorOrderId,
    currentPOStatus,
    setCurrentPOStatus,
    poStatuses,
    reviewReceivedSerials,
    receivedSerials,
    resetReceivedSerials,
    imsLocations,
    handleLocationChange,
    selectedLocationCode,
    poHasDirtyFields,
    setPOHasDirtyFields,
    handleSubmitPOUpdate,
    poIsPaid,
    setPOIsPaid,
    dfiDiscount,
    handleDfiDiscount,
    poTerms,
    selectedPoTerm,
    handleSelectPoTerm,
    expectedArrivalDate,
    handleUpdateExpectedArrivalDate,
    applyExpectedArrivalDateToAllLineItems,
    handleVendorUpdate,
    selectedVendor
  } = props;
  const classes = usePurchaseOrderDetailsStyles();
  const createdAtRaw = poData.PO.created_at ? moment(poData.PO.created_at) : moment();
  const createdAt = createdAtRaw.format('MMMM Do, YYYY');

  const poIsBuilding =
    poData &&
    poData.PO &&
    poData.PO.purchase_order_status &&
    (poData.PO.purchase_order_status.status_name === 'New' ||
      poData.PO.purchase_order_status.status_name === 'Building');
  const poIsFinished =
    poData &&
    poData.PO &&
    poData.PO.purchase_order_status &&
    poData.PO.purchase_order_status.status_name === 'Complete';

  const [invoicesOpen, setInvoicesOpen] = useState<boolean>(false);
  const updatePOButtonRef = useRef<HTMLButtonElement>(null);
  const printPOButtonRef = useRef<HTMLButtonElement>(null);
  const savePOButtonRef = useRef<HTMLButtonElement>(null);
  const attachInvoiceButtonRef = useRef<HTMLButtonElement>(null);

  const toggleInvoiceOpen = (): void => {
    setInvoicesOpen(!invoicesOpen);
  };

  const scanEventHandler = useCallback<ScanEventHandler>(
    scanEvent => {
      if (looksLikeUpc(scanEvent.scannedValue)) {
        scanEvent.stopPropagation();
        const matchingPoLine = poData.PO_LINES.find(
          poLine =>
            matchesUpc(scanEvent.scannedValue, poLine.product) &&
            poLine.qty_received < poLine.qty_ordered
        );
        if (matchingPoLine && !poIsFinished) {
          initializeReceiving(matchingPoLine);
        }
      }
    },
    [poData.PO_LINES, initializeReceiving, poIsFinished]
  );

  return (
    <ScanEventSubscriber onScan={scanEventHandler}>
      <Paper>
        <div style={{ padding: 25 }}>
          <Grid container alignItems="center" spacing={1} style={{ marginBottom: 20 }}>
            <Grid item alignItems="center">
              <div>
                <Typography variant="h2" style={{ fontWeight: 'bold', marginRight: 25 }}>
                  <GlueCopyToClipboardIconButton parentTypographyVariant="h6">
                    {poData.PO.purchase_ref_id}
                  </GlueCopyToClipboardIconButton>
                  {poData.PO.legacy_po_number ? `(Legacy PO #${poData.PO.legacy_po_number})` : ''}
                  {!poData.PO.legacy_po_number && !(poData.PO.purchase_ref_id || '').includes('W') && (
                    <Tooltip
                      title={
                        <>
                          <h2>PO Number Collision</h2>
                          <Typography>
                            This PO has a duplicate PO number as it was created in the new system.
                          </Typography>
                        </>
                      }
                    >
                      <Warning color="error" />
                    </Tooltip>
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={1}>
              <Typography>
                <strong>Status</strong>
              </Typography>
              <Select
                isDisabled={poIsUpdating}
                options={poStatuses.map(poStatus => ({
                  label: poStatus.status_name,
                  value: poStatus.id
                }))}
                value={{
                  label: currentPOStatus.status_name,
                  value: currentPOStatus.id
                }}
                onChange={(v: any) => {
                  setCurrentPOStatus({
                    id: v.id,
                    status_name: v.label
                  });
                  setPOHasDirtyFields(true);
                }}
              />
            </Grid>
            {poHasDirtyFields && (
              <Button
                className={classes.edit}
                buttonRef={updatePOButtonRef}
                variant="outlined"
                onClick={() => {
                  handleSubmitPOUpdate();
                  if (updatePOButtonRef.current) {
                    updatePOButtonRef.current.blur();
                  }
                }}
              >
                Update PO
              </Button>
            )}
            <Grid item xs={1}>
              <Can
                role={role || 'user'}
                perform="purchase-order:edit"
                yes={() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={poIsPaid}
                        color="primary"
                        onChange={e => {
                          setPOIsPaid(e.target.checked);
                          setPOHasDirtyFields(true);
                        }}
                      />
                    }
                    label="Paid?"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid container lg={6} spacing={1}>
              <Grid item xs={2}>
                <Typography>
                  <strong>Ship To</strong>
                </Typography>
                <Select
                  options={imsLocations.map(imsLocation => ({
                    label: imsLocation.location_code,
                    value: imsLocation.location_code
                  }))}
                  onChange={handleLocationChange}
                  value={{
                    label: selectedLocationCode,
                    value: selectedLocationCode
                  }}
                  isDisabled={!check(role || 'user', 'purchase-order:edit') || poIsUpdating}
                />
              </Grid>

              <Grid item xs={4}>
                <div>
                  <Typography>
                    <strong>Vendor</strong>
                  </Typography>
                  <Select
                    onChange={(v: any) => handleVendorUpdate(parseInt(v.value))}
                    variant="outlined"
                    value={vendors
                      .filter((vendor: any) =>
                        vendor.value ? vendor.value === selectedVendor : vendor === selectedVendor
                      )
                      .pop()}
                    options={vendors}
                    isDisabled={!check(role || 'user', 'purchase-order:edit') || poIsUpdating}
                  />
                </div>
              </Grid>

              <Grid item xs={2}>
                <Typography>
                  <strong>PO Terms</strong>
                </Typography>
                <Select
                  options={poTerms?.map((poTerm: any) => ({
                    label: poTerm.type,
                    value: poTerm.id
                  }))}
                  onChange={handleSelectPoTerm}
                  value={{
                    label: selectedPoTerm.type,
                    value: selectedPoTerm.id
                  }}
                  isDisabled={!check(role || 'user', 'purchase-order:edit') || poIsUpdating}
                />
              </Grid>
              <Grid item>
                <Typography>
                  <strong>DFI Discount Percentage</strong>
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={dfiDiscount}
                  disabled={poIsUpdating}
                  onChange={handleDfiDiscount}
                />
              </Grid>

              {!poIsBuilding && check(role || 'user', 'purchase-order:edit') && (
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography>
                      <strong>Carrier</strong>
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      onChange={e => {
                        setPOCarrier(e.target.value);
                        setPOHasDirtyFields(true);
                      }}
                      value={poCarrier || ''}
                      disabled={poIsFinished || poIsUpdating}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <strong>Tracking Number</strong>
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      onChange={e => {
                        setPOTrackingNumber(e.target.value);
                        setPOHasDirtyFields(true);
                      }}
                      value={poTrackingNumber || ''}
                      disabled={poIsFinished || poIsUpdating}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <strong>Vendor Order #</strong>
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      onChange={e => {
                        setVendorOrderId(e.target.value);
                        setPOHasDirtyFields(true);
                      }}
                      value={vendorOrderId || ''}
                      disabled={poIsFinished || poIsUpdating}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item>
                <Typography>
                  <strong>Expected Arrival Date</strong>
                </Typography>
                <div style={{ display: 'flex' }}>
                  <TextField
                    variant="outlined"
                    style={{ marginRight: '0.5rem' }}
                    size="small"
                    type="date"
                    onChange={e => handleUpdateExpectedArrivalDate(e.target.value)}
                    value={expectedArrivalDate}
                  />
                  <Button variant="outlined" onClick={applyExpectedArrivalDateToAllLineItems}>
                    Update Expected Arrival
                  </Button>
                </div>
              </Grid>
              {!poIsFinished && poData.PO_LINES.length > 0 && (
                <Grid container spacing={1}>
                  <Can
                    role={role || 'user'}
                    perform="purchase-order:edit"
                    yes={() => (
                      <>
                        {poData.PO.purchase_order_status &&
                          poData.PO.purchase_order_status.status_name === 'Order Sent' && (
                            <Grid item>
                              <Button
                                onClick={handleUpdatePOStatusToOrderConfirmed}
                                variant="outlined"
                                size="small"
                              >
                                Confirm PO From Vendor
                              </Button>
                            </Grid>
                          )}
                        <Grid item>
                          <Button
                            className={classes.edit}
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setPOEditingDisabled(!poEditingDisabled);
                            }}
                          >
                            {poEditingDisabled ? 'Edit PO' : 'Done Editing'}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              handleFinishPurchaseOrder();
                            }}
                          >
                            Complete PO (Finish)
                          </Button>
                        </Grid>
                      </>
                    )}
                  />
                  <Grid item>
                    <Button
                      buttonRef={printPOButtonRef}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        printPurchaseOrderAsPDF(poData.PO.id);
                        if (printPOButtonRef.current) {
                          printPOButtonRef.current.blur();
                        }
                      }}
                    >
                      Print PO
                    </Button>
                  </Grid>
                  <Can
                    role={role}
                    perform="purchase-order:send-to-vendor"
                    yes={() => (
                      <Grid item>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => setComposingPOEmail(true)}
                        >
                          Send PO to Vendor
                        </Button>
                      </Grid>
                    )}
                  />
                  <Grid item>
                    <Button
                      buttonRef={savePOButtonRef}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        savePurchaseOrderAsPDF(poData.PO.id);
                        if (savePOButtonRef.current) {
                          savePOButtonRef.current.blur();
                        }
                      }}
                    >
                      Save PO as PDF
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid item sm={3}>
                  <Typography>
                    <strong>Created On:</strong> {createdAt}
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography>
                    <strong>Created By:</strong> {poData.PO.created_by}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {!poIsFinished && (
              <Grid item sm={4}>
                <Can
                  role={role || 'user'}
                  perform="purchase-order:edit"
                  yes={() => <ProductSearchForPO addProductToPO={addProductToPO} />}
                />
              </Grid>
            )}
          </Grid>

          <Dialog fullWidth open={composingPOEmail} onClose={() => setComposingPOEmail(false)}>
            <div className={classes.dialog}>
              <FormControl>
                <TextField
                  name="rep_email"
                  label="To Whom Will This Be Sent?"
                  fullWidth
                  className={classes.rep_email}
                  value={poVendorEmail}
                  onChange={event => setPOVenderEmail(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <TextField
                  name="cc"
                  label="CC Emails, separated by comma"
                  fullWidth
                  className={classes.rep_email}
                  value={poEmailCCs}
                  onChange={event => setPOEmailCCs(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <TextField
                  name="email_message"
                  label="Recipient Name"
                  className={classes.rep_email}
                  fullWidth
                  value={poEmailRecipientName}
                  onChange={event => setPOEmailRecipientName(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <TextField
                  name="email_message"
                  label="Additional Notes"
                  variant="outlined"
                  className={classes.email_message}
                  multiline
                  value={poEmailContent}
                  onChange={event => setPOEmailContent(event.target.value)}
                />
              </FormControl>
              <div>
                <Button
                  variant="contained"
                  onClick={() =>
                    sendPurchaseOrderAsPDF(
                      poData.PO.id,
                      poEmailContent,
                      poEmailRecipientName,
                      poVendorEmail,
                      poEmailCCs
                    )
                      .then(() => setComposingPOEmail(false))
                      .then(() => showPurchaseOrder())
                  }
                >
                  Send
                </Button>
              </div>
            </div>
          </Dialog>

          {poData.PO_LINES.map(poItem => {
            return (
              <POLineItem
                key={poItem.id}
                poEditingDisabled={poEditingDisabled}
                poItem={poItem}
                finished={poIsFinished || false}
                role={role}
                updatePOItem={handleUpdatePOItem}
                deletePOItem={handleDeletePOItem}
                initializeReceiving={initializeReceiving}
                initializeUnReceiving={initializeUnReceiving}
                reviewSerials={() => reviewReceivedSerials(poItem.id)}
              />
            );
          })}

          <PurchaseOrderMeta poData={poData} />

          {receivedSerials && (
            <Dialog open={!isEmpty(receivedSerials)} onClose={() => resetReceivedSerials()}>
              <div style={{ padding: 20 }}>
                <Button onClick={() => resetReceivedSerials()}>
                  <Close />
                </Button>
                <h2>Serials</h2>
                {receivedSerials === 'No serials found' ? (
                  <p>{NO_SERIALS_FOUND_MESSAGE}</p>
                ) : (
                  <TableView header={['Serial', 'Scanned On', 'Disposition', 'Notes']}>
                    {receivedSerials.map((serial, i) => (
                      <TableRow
                        key={
                          serial.length
                            ? `${serial[0].fk_inventory_serial_id}-${serial[0].scanned_at}`
                            : i
                        }
                      >
                        <TableCell>
                          <GlueCopyToClipboardIconButton>
                            {serial.map(subSerial => subSerial.scanned_serial_number).join(',')}
                          </GlueCopyToClipboardIconButton>
                        </TableCell>
                        <TableCell>
                          {serial.map(subSerial => subSerial.scanned_at).join(',')}
                        </TableCell>
                        <TableCell>
                          {serial.map(subSerial => subSerial.receiving_disposition_code).join(',')}
                        </TableCell>
                        <TableCell>{serial.map(subSerial => subSerial.notes).join(',')}</TableCell>
                      </TableRow>
                    ))}
                  </TableView>
                )}
              </div>
            </Dialog>
          )}

          {poItemToReceive && (
            <PurchaseOrderReceiving
              dialogIsOpen={isReceiving}
              poItemToReceive={poItemToReceive}
              poData={poData}
              onClose={() => endReceiving()}
            />
          )}
          {poItemToReceive && (
            <PurchaseOrderUnReceiving
              dialogIsOpen={isUnReceiving}
              poItem={poItemToReceive}
              onClose={() => endUnReceiving()}
            />
          )}
          <PurchasingNotes purchaseOrder={poData.PO} showPurchaseOrder={showPurchaseOrder} />
          <AttachedInvoices purchaseOrder={poData.PO.id} />
          <Button
            buttonRef={attachInvoiceButtonRef}
            onClick={() => {
              toggleInvoiceOpen();
              if (attachInvoiceButtonRef.current) {
                attachInvoiceButtonRef.current.blur();
              }
            }}
            variant="contained"
          >
            Attach an Invoice
          </Button>
        </div>
      </Paper>
      <Dialog open={invoicesOpen} fullWidth maxWidth="lg" onClose={toggleInvoiceOpen}>
        <DialogTitle>
          <Button onClick={toggleInvoiceOpen}>
            <Close />
          </Button>
          <h2>Attach an Invoice</h2>
        </DialogTitle>
        <DialogContent>
          <AccountsPayableInvoices purchaseOrder={poData.PO.id} />
        </DialogContent>
      </Dialog>
    </ScanEventSubscriber>
  );
}
