import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import { useHttp } from '../../common/Hooks';

import CreateVendor from './CreateVendor';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    padding: 15
  }
});

const Vendor = props => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const [request, response] = useHttp('vendor');
  const [vendorList, setVendorList] = useState([]);
  const [modalChange, setModalChange] = useState(false);
  const [editChange, setEditChange] = useState(false);
  const [newRowID, setRowID] = useState(null);
  const [errorState, setErrorState] = useState(false);

  function remove(rowID) {
    setModalChange(true);
    setRowID(rowID);
  }

  async function requestRemove() {
    if (newRowID) {
      await request.delete(`/${newRowID}`);
      setVendorList(vendorList => vendorList.filter(current => current.id !== newRowID));
      setModalChange(false);
      setRowID(null);
    }
  }

  async function getVendor() {
    const newVendorList = await request.get();
    if (response.ok) {
      setVendorList(newVendorList.data);
    }
  }

  useEffect(() => {
    getVendor();
  }, []);

  function edit(rowID) {
    setEditChange(true);
    setRowID(rowID);
  }

  async function requestEdit(state) {
    if (newRowID) {
      await request.put(`/${newRowID}`, state);
      if (response.ok) {
        setEditChange(false);
        setRowID(null);
        getVendor();
      } else {
        setErrorState(true);
      }
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Button variant="contained" onClick={handleClose}>
          <Close />
          Close
        </Button>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell align="left">Company Name</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">City</TableCell>
                <TableCell align="left">State</TableCell>
                <TableCell align="left">Zip</TableCell>
                <TableCell align="left">Rep Name</TableCell>
                <TableCell align="left">Rep Phone</TableCell>
                <TableCell align="left">Rep Email</TableCell>
                <TableCell align="left">Fax</TableCell>
                <TableCell align="left">Account</TableCell>
                <TableCell align="left">Notes</TableCell>
                <TableCell align="left">Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendorList.map(current => {
                return (
                  <TableRow>
                    <TableCell>
                      <Button onClick={() => edit(current.id)} variant="contained">
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => remove(current.id)} variant="contained">
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell>{current.company_name}</TableCell>
                    <TableCell>{current.address}</TableCell>
                    <TableCell>{current.city}</TableCell>
                    <TableCell>{current.state}</TableCell>
                    <TableCell>{current.zip}</TableCell>
                    <TableCell>{current.rep}</TableCell>
                    <TableCell>{current.rep_phone}</TableCell>
                    <TableCell>{current.rep_email}</TableCell>
                    <TableCell>{current.fax_phone}</TableCell>
                    <TableCell>{current.dealer_number}</TableCell>
                    <TableCell>{current.notes}</TableCell>
                    <TableCell>{current.vendor_type}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {newRowID ? (
          <CreateVendor
            editVendor
            handleVendorSubmit={requestEdit}
            open={editChange}
            handleClose={() => setEditChange(false) || setRowID(null)}
            row={vendorList.find(current => newRowID === current.id)}
          />
        ) : null}
        {errorState && <Alert severity="error">Vendor Name Required</Alert>}
        <Dialog classes={classes} open={modalChange} onClose={() => setModalChange(false)}>
          Are you sure you want to Delete?
          <Button onClick={() => requestRemove()}>Yes</Button>
          <Button onClick={() => setModalChange(false) || setRowID(null)}>No</Button>
        </Dialog>
      </Dialog>
    </div>
  );
};

export default Vendor;
