import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Close from '@material-ui/icons/Close';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';

const StyledTextField = styled(TextField)({
  marginLeft: 16,
  marginRight: 16
});

const StyledNativeSelect = styled(Select)({
  select: {
    marginTop: 16
  }
});

const StyledFormControl = styled(FormControl)({
  flexDirection: 'row',
  flexWrap: 'wrap'
});

const contactFields = [
  { name: 'Company Name', type: 'text', key: 'company_name' },
  {
    name: 'Default PO Terms',
    type: 'select',
    fields: ['Net 30', 'Net 60', 'Net 90'],
    key: 'default_purchase_terms'
  },
  {
    name: 'Vendor Type',
    type: 'select',
    fields: ['sales', 'service'], // 'Product', 'Service', 'Seller' was previous options
    key: 'vendor_type'
  },
  { name: 'Rep', type: 'text', key: 'rep' },
  { name: 'Rep Email', type: 'email', key: 'rep_email' },
  { name: 'Dealer Number', type: 'text', key: 'dealer_number' },
  { name: 'Rep Phone', type: 'number', key: 'rep_phone' },
  { name: 'Program Name', type: 'text', key: 'program_name' },
  { name: 'Address', type: 'text', key: 'address' },
  { name: 'Address 2', type: 'text', key: 'address2' },
  { name: 'City', type: 'text', key: 'city' },
  { name: 'State', type: 'text', key: 'state' },
  { name: 'ZIP', type: 'text', key: 'zip' },
  { name: 'Country', type: 'text', key: 'country' }
];

class CreateVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.vendorFields = this.vendorFields.bind(this);
  }

  componentWillUnmount() {
    this.setState({});
  }

  static getDerivedStateFromProps(props, state) {
    if (props.row && isEmpty(state) && props.editVendor) {
      return contactFields.reduce((curstate, field) => {
        curstate[camelCase(field.name)] = props.row[field.key];
        return curstate;
      }, {});
    }
    return state;
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const values = { ...this.state };
    this.setState({}, () => this.props.handleVendorSubmit(values));
  }

  vendorFields({ field, key }) {
    switch (field.type) {
      case 'select': {
        return (
          <FormControl key={key}>
            <InputLabel shrink>{field.name}</InputLabel>
            <StyledNativeSelect
              displayEmpty
              value={this.state[camelCase(field.name)]}
              name={camelCase(field.name)}
              onChange={this.handleChange}
            >
              {field.fields.map((f, i) => (
                <option key={i} value={f}>
                  {f.replace('_', ' ')}
                </option>
              ))}
            </StyledNativeSelect>
          </FormControl>
        );
      }
      default: {
        return (
          <FormControl key={key}>
            <StyledTextField
              value={this.state[camelCase(field.name)]}
              name={camelCase(field.name)}
              id={camelCase(field.name)}
              label={field.name}
              type={field.type}
              margin="normal"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        );
      }
    }
  }

  render() {
    const VendorFields = this.vendorFields;
    return (
      <Dialog fullWidth maxWidth={false} onClose={this.props.handleClose} open={this.props.open}>
        <DialogContent>
          <Button variant="contained" onClick={this.props.handleClose}>
            <Close />
            Close
          </Button>
          <DialogTitle>{this.props.row ? 'Edit Vendor' : 'Create a New Vendor'}</DialogTitle>
          <form>
            <DialogTitle>Contact</DialogTitle>
            <StyledFormControl>
              {contactFields.map((item, key) => (
                <VendorFields field={item} key={key} />
              ))}
            </StyledFormControl>
            <FormControl>
              <Button onClick={this.handleSubmit} variant="contained">
                {this.props.row ? 'Edit Vendor' : 'Create a New Vendor'}
              </Button>
            </FormControl>
          </form>
          {this.props.vendorErrors && (
            <Alert severity="error">Company Name and Vendor Type Required</Alert>
          )}
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
  }
}

export default connect(state => {
  return {
    vendorErrors: state.purchasing.vendorError
  };
}, {})(CreateVendor);
