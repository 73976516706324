import React, { useState } from 'react';
import { Create } from '@material-ui/icons';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import CreateVendor from './CreateVendor';
import Vendor from './Vendor';

const styles = () => {
  return {
    root: {
      marginLeft: 16
    }
  };
};

const SelectVendor = props => {
  const [vendorToggled, setVendorToggled] = useState(false);

  const { classes } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 750
      }}
    >
      <NativeSelect
        onChange={e => props.handleChange(e.target.value)}
        value={props.selectedVendor.id}
        name="vendors"
      >
        <option value={props.selectedVendor.id || 'Select a Vendor'}>
          {props.selectedVendor.company_name || 'Select a Vendor'}
        </option>
        {props.vendors
          .sort((a, b) => {
            let comparison = 0;
            if (a.company_name.toUpperCase() > b.company_name.toUpperCase()) {
              comparison = 1;
            } else if (a.company_name.toUpperCase() < b.company_name.toUpperCase()) {
              comparison = -1;
            }
            return comparison;
          })
          .map((item, key) => {
            return (
              <option key={key} value={item.id}>
                {item.company_name}
              </option>
            );
          })}
      </NativeSelect>
      <Button classes={classes} onClick={() => props.newVendorToggled(true)}>
        <Create />
        Add New Vendor
      </Button>

      <Button classes={classes} onClick={() => setVendorToggled(true)}>
        <Create />
        Edit/Delete Vendor
      </Button>

      <Vendor
        //not using props??
        open={vendorToggled}
        handleClose={() => setVendorToggled(false)}
      />

      {props.newVendor && (
        <CreateVendor
          handleVendorSubmit={props.handleVendorSubmit}
          open={props.newVendor}
          handleClose={props.handleClose}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(SelectVendor);
