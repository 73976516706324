import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { orderBy } from 'natural-orderby';
import PurchaseOrderList from './PurchaseOrderList';
import { UserContext } from '../../roles/User';
import { useHttp } from '../../common/Hooks';

export function optionFormat([request, response], field) {
  return response.ok && Array.isArray(get(request, 'data.data', null))
    ? request.data.data.map(item => {
        return { value: item.id, label: item[field] };
      })
    : [];
}

function changeSortOrder(pos, sortKey, ascending) {
  const listToSort = [].concat(pos);
  return orderBy(listToSort, [sortKey], [ascending ? 'asc' : 'desc']);
}

export default function PurchaseOrderListContainer() {
  const allowed = [
    'id',
    'vendor',
    'purchase_ref_id',
    'purchase_order_status',
    'created_by',
    'created_at',
    'legacy_po_number',
    'purchase_order_submission_status',
    'ims_location_code',
    'paid',
    'total',
    'total_received'
  ];

  const { location } = useHistory();
  const {
    fromDate,
    toDate,
    poNumber,
    modelNumber,
    vendorOrderId,
    selectedStatus,
    selectedVendor,
    selectedShipToLocation,
    selectedUser,
    paid
  } = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(location.search));
  }, [location.search]);

  const debounceTime = 500;
  const [finished, setFinished] = useState(false);
  const [poNumberDebounced] = useDebounce(poNumber, debounceTime);
  const [modelNumberDebounced] = useDebounce(modelNumber, debounceTime);
  const [vendorOrderIdDebounced] = useDebounce(vendorOrderId, debounceTime);
  const [statusDebounced] = useDebounce(selectedStatus, debounceTime);
  const [legacyPoNum, setLegacyPoNumber] = useState('');
  const [legacyPoNumDebounced] = useDebounce(legacyPoNum, setLegacyPoNumber);
  const [modelSearch, setModelSearch] = useState(false);
  const [purchaseOrderRequest] = useHttp('');
  const [ascending, setAscending] = useState(false);
  const [loading, setLoading] = useState(null);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [orderKey, setOrderKey] = useState('purchase_ref_id');

  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const [createPO, setCreatePO] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (modelNumber) {
      purchaseOrderRequest.get(`purchaseByModel?model=${modelNumber}`).then(async data => {
        setModelSearch(true);
        setLoading(false);
        // This is a hack that needs to be better handled
        // Right now, it's filtering out the "blank" vendor in production
        setPurchaseOrders(data.filter(item => item.vendor_id !== 127));
      });
    } else {
      const searchParams = new URLSearchParams(
        `fromDate=${fromDate || ''}&toDate=${toDate || ''}&modelNumber=${modelNumber ||
          ''}&vendorOrderId=${vendorOrderId || ''}&poNumber=${poNumber || ''}`
      );
      if (selectedStatus) {
        searchParams.set('finished', selectedStatus);
      }
      if (selectedVendor) {
        searchParams.set('vendor', selectedVendor);
      }
      if (selectedUser) {
        searchParams.set('user', selectedUser);
      }
      if (selectedShipToLocation) {
        searchParams.set('shipTo', selectedShipToLocation);
      }
      if (legacyPoNum) {
        searchParams.set('legacyPoNum', legacyPoNum);
      }
      if (paid) {
        searchParams.set('paid', paid === 'true' ? 1 : 0);
      }
      purchaseOrderRequest.get(`purchase?${searchParams.toString()}`).then(async data => {
        const onlyFields = data.data
          .filter(item => item.vendor_id !== 127)
          .map(po => {
            return Object.entries(po).reduce((acc, entry) => {
              if (allowed.includes(entry[0])) {
                if (entry[1] === null) {
                  acc[entry[0]] = '';
                } else if (entry[1].company_name) {
                  acc[entry[0]] = entry[1].company_name;
                } else if (!entry[1].status_name) {
                  if (entry[1] === 0 || entry[1] === 1) {
                    acc[entry[0]] = startCase((entry[1] === 1).toString());
                  } else {
                    // eslint-disable-next-line prefer-destructuring
                    acc[entry[0]] = entry[1];
                  }
                } else {
                  acc[entry[0]] = entry[1].status_name;
                }
              }
              return acc;
            }, {});
          });
        setPurchaseOrders(onlyFields);
        setLoading(false);
        setModelSearch(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    finished,
    fromDate,
    toDate,
    selectedVendor,
    poNumberDebounced,
    vendorOrderIdDebounced,
    modelNumberDebounced,
    statusDebounced,
    legacyPoNumDebounced,
    selectedUser,
    selectedShipToLocation,
    paid
  ]);

  const vendors = useHttp('vendor', {
    ...options,
    onMount: true
  });
  const statuses = useHttp('purchase/status', {
    ...options,
    onMount: true
  });
  const statusesFormatted = optionFormat(statuses, 'status_name');
  const vendorListFormatted = optionFormat(vendors, 'company_name');
  const users = useHttp('user/all', {
    ...options,
    onMount: true
  });
  const userListFormatted = optionFormat(users, 'name');
  const isLoading = loading || vendors.loading || users.loading;
  const user = useContext(UserContext);
  const shipToLocations = useHttp('ims/canReceivePos', {
    ...options,
    onMount: true
  });
  const shipToLocationsFormatted = shipToLocations[0].data
    ? shipToLocations[0].data.map(item => ({
        label: item.location_code,
        value: item.location_code
      }))
    : [];

  function updatePoListOrder(sortKey) {
    setAscending(!ascending);
    const newlySorted = changeSortOrder(purchaseOrders, sortKey, ascending);
    setPurchaseOrders(newlySorted);
    setOrderKey(sortKey);
  }

  useEffect(() => {
    const newlyOrdered = changeSortOrder(purchaseOrders, orderKey, ascending);
    setPurchaseOrders(newlyOrdered);
  }, [orderKey, ascending]);

  return (
    <PurchaseOrderList
      loading={isLoading}
      purchaseOrders={purchaseOrders}
      updatePoListOrder={updatePoListOrder}
      vendors={vendorListFormatted}
      finished={finished}
      setFinished={() => {
        setFinished(!finished);
      }}
      users={userListFormatted}
      role={user.role}
      statuses={statusesFormatted}
      createPO={createPO}
      setCreatePO={setCreatePO}
      shipToLocations={shipToLocationsFormatted}
      legacyPoNumber={legacyPoNum}
      setLegacyPoNumber={e => setLegacyPoNumber(e.target.value)}
      modelSearch={modelSearch}
      ascending={ascending}
      changeSortOrder={() => setAscending(!ascending)}
      orderKey={orderKey}
    />
  );
}
